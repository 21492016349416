<ion-header class="ion-no-border">
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-menu-button class="header-menu-button"></ion-menu-button>
    </ion-buttons>
    <ion-title>Configurações</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="settings-content ion-padding">
  @for(type of maintenanceTypes(); track type) {
    <div class="settings-section">
      <h2>{{ type.name }}</h2>

      <ion-item lines="none">
        <ion-toggle
          [(ngModel)]="type.status().active"
          (ionChange)="markAsModified(type.key)"
        >Manutenção Ativa</ion-toggle>
      </ion-item>

      <ion-item>
        <ion-input label="Mensagem de Manutenção" labelPlacement="stacked"
          [(ngModel)]="type.status().message"
          (ionInput)="markAsModified(type.key)"
          placeholder="Digite a mensagem"
        ></ion-input>
      </ion-item>

      <ion-item>
        <ion-datetime
          presentation="time"
          label="Duração da Manutenção (HH:MM)"
          labelPlacement="stacked"
          [(ngModel)]="type.status().time"
        ></ion-datetime>
      </ion-item>

      <div class="button-container">
        <ion-button
          expand="block"
          color="primary"
          (click)="saveChanges(type.key, type.status())"
        >
          Salvar
        </ion-button>
      </div>
    </div>
  }
</ion-content>

