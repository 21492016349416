import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { CanalTokenType, NotificationRegisterRequest, PlatformCore } from '@libs/entity-lib';
import { VibrationService } from '@libs/util-services';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { NotificationSettingsFacadeService } from '../core/services/notification-settings/notification-settings-facade.service';
import * as fromNotificationActions from '../core/store/notification/notification.actions';
import { selectTokenMobile, selectTokenWeb } from '../core/store/notification/notification.selectors';

@Component({
    selector: 'notifications-lib',
    templateUrl: 'notifications-lib.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class NotificationLibComponent implements OnInit, OnDestroy {

  tokenWeb = this.store.selectSignal(selectTokenWeb);
  tokenMobile = this.store.selectSignal(selectTokenMobile);

  
  platformCore = PlatformCore;
  platformCoreSelected: PlatformCore;

  source:string;
  canalTokenType = CanalTokenType;

  private subscriptions = new Subscription();

  isWeb = signal(false);
  constructor(private store: Store,
    private notificationSettingsFacadeService: NotificationSettingsFacadeService,
    private vibrationService: VibrationService) {
    this.checkPlatform();
    this.fetchNotificationTokens(); 
  }

  ngOnInit(): void {
      this.setIsWeb();
  }

  fetchNotificationTokens() {
    this.store.dispatch(fromNotificationActions.fetchNotificationTokens());
  }
  
  setIsWeb(): void {
    this.isWeb.set(this.platformCoreSelected === PlatformCore.WEB);
  }

  checkPlatform(): void {
    if(Capacitor.getPlatform() === 'web') {
      this.platformCoreSelected = PlatformCore.WEB;
    } else {
      this.platformCoreSelected = PlatformCore.MOBILE;
    }
  }
  

  requestNotificationPermissionWithMobile(): void {
    this.vibrationService.hapticsImpactLight();
    this.notificationSettingsFacadeService.requestNotificationPermissionWithMobile();
  }

  requestNotificationPermissionWeb(): void {
    this.notificationSettingsFacadeService.requestNotificationPermissionWeb();
  }

  resetToken(canalTokenType: CanalTokenType) {
    this.vibrationService.hapticsImpactLight();
    const notificationRequest = { 
      token: null, canalTokenType 
    } as NotificationRegisterRequest;
    this.store.dispatch(fromNotificationActions.resetToken({ notificationRequest }));
  }

  getToken(): void {
    this.store.dispatch(fromNotificationActions.getToken());
  }


  requestWebNotificationPermission(): void {
    this.vibrationService.hapticsImpactLight();
    this.store.dispatch(fromNotificationActions.requestPermissionsWithWeb());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
