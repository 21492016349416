import {
  accessibilityOutline,
  add,
  addCircleOutline,
  addOutline,
  alarmOutline,
  alertCircleOutline,
  analyticsOutline,
  arrowDownOutline,
  arrowUpOutline,
  barChartOutline,
  cafeOutline,
  calendarOutline,
  callOutline,
  cardOutline,
  carOutline,
  cartOutline,
  cashOutline,
  chatboxEllipsesOutline,
  checkbox,
  checkboxOutline,
  checkmarkCircleOutline,
  checkmarkDoneCircleOutline,
  checkmarkDoneOutline,
  checkmarkOutline,
  chevronDownOutline,
  chevronExpandOutline,
  chevronForwardOutline,
  chevronUp,
  chevronUpOutline,
  close,
  construct,
  copyOutline,
  createOutline,
  documentTextOutline,
  ellipse,
  ellipsisHorizontal,
  ellipsisVertical,
  eyeOffOutline,
  eyeOutline,
  fastFoodOutline,
  flagOutline,
  footballOutline,
  footstepsOutline,
  gitCompareOutline,
  homeOutline,
  informationCircleOutline,
  linkOutline,
  locationOutline,
  lockClosedOutline,
  lockOpenOutline,
  logInOutline,
  logOutOutline,
  logoWhatsapp,
  mailOpenOutline,
  maleFemaleOutline,
  mapOutline,
  megaphoneOutline,
  notificationsCircleOutline,
  notificationsOutline,
  pencilOutline,
  peopleOutline,
  personAddOutline,
  personCircleOutline,
  personOutline,
  playCircleOutline,
  readerOutline,
  refreshCircleOutline,
  removeCircleOutline,
  restaurantOutline,
  saveOutline,
  sendOutline,
  shieldCheckmarkOutline,
  squareOutline,
  star,
  storefrontOutline,
  tennisballOutline,
  timeOutline,
  trailSignOutline,
  trashOutline,
  trophyOutline,
  videocamOffOutline,
  waterOutline,
  wifiOutline,
} from 'ionicons/icons';
import { addIcons } from 'ionicons';

export function configureIcons() {
  addIcons({
    add,
    logOutOutline,
    chevronUp,
    calendarOutline,
    ellipsisHorizontal,
    ellipsisVertical,
    checkmarkOutline,
    checkmarkDoneOutline,
    chevronForwardOutline,
    personOutline,
    footballOutline,
    ellipse,
    addCircleOutline,
    close,
    createOutline,
    informationCircleOutline,
    alarmOutline,
    chatboxEllipsesOutline,
    flagOutline,
    timeOutline,
    accessibilityOutline,
    eyeOutline,
    star,
    construct,
    checkmarkCircleOutline,
    storefrontOutline,
    copyOutline,
    squareOutline,
    checkboxOutline,
    callOutline,
    mapOutline,
    documentTextOutline,
    notificationsOutline,
    notificationsCircleOutline,
    checkmarkDoneCircleOutline,
    readerOutline,
    logInOutline,
    homeOutline,
    personCircleOutline,
    mailOpenOutline,
    shieldCheckmarkOutline,
    cashOutline,
    sendOutline,
    trashOutline,
    checkbox,
    chevronDownOutline,
    wifiOutline,
    waterOutline,
    maleFemaleOutline,
    carOutline,
    footstepsOutline,
    videocamOffOutline,
    fastFoodOutline,
    restaurantOutline,
    cafeOutline,
    cardOutline,
    chevronUpOutline,
    removeCircleOutline,
    playCircleOutline,
    addOutline,
    saveOutline,
    pencilOutline,
    analyticsOutline,
    gitCompareOutline,
    personAddOutline,
    logoWhatsapp,
    trophyOutline,
    lockOpenOutline,
    lockClosedOutline,
    cartOutline,
    peopleOutline,
    locationOutline,
    tennisballOutline,
    barChartOutline,
    linkOutline,
    trailSignOutline,
    alertCircleOutline,
    megaphoneOutline,
    refreshCircleOutline,
    eyeOffOutline,
    chevronExpandOutline,
    arrowDownOutline,
    arrowUpOutline
  });
}