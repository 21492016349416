import { SportCenterPricing, SportCenterPricingSegment } from '@libs/entity-lib';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './pricing.actions';

export interface State {
  loading: boolean;
  pricingList: SportCenterPricing[];
  pricingSelected: SportCenterPricing | null;
  segment: SportCenterPricingSegment | null;
  error: any | null;
}

export const initialState: State = {
  loading: false,
  pricingList: [],
  pricingSelected: null,
  segment: null,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(fromActions.fetchAll, (state) => ({ ...state, loading: true })),
  on(fromActions.fetchAllSuccefully, (state, { pricingList }) => ({ ...state, pricingList, loading: false })),
  on(fromActions.fetchAllFailed, (state, { error }) => ({ ...state, error, loading: false })),
  on(fromActions.subscribeSelected, (state, { pricing, segment }) => ({ ...state, pricingSelected: pricing, segment })),
);
