import { Routes } from '@angular/router';
import {
  AuthRoutesEnum,
  CourtsRoutesEnum,
  EmployeeRoutesEnum,
  NotificationRoutesEnum,
  ProfileRoutesEnum,
  SchedulerRoutesEnum,
  CrudSportCenterEnum,
  OpeningHoursRoutesEnum,
  HomeRoutesEnum,
  SettingsPageEnum,
  ClassesRoutesEnum
} from '@libs/entity-lib';
import { AuthGuard } from './guards/auth.guard';
import { updateLanguageRoutes } from '@libs/translation';
import { fixedTimeRoutes, financialDashboardRoutes, chatRoutes } from '@libs/util-ui';
import { classesRoutes } from '@libs/class-management';
import { clientRoutes } from '@libs/client';
import { maintenanceRoutes } from '@libs/maintenance';
import { commonEnvironment } from '@libs/shared/util-environments';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: HomeRoutesEnum.HOME,
    pathMatch: 'full',
  },
  {
    path: 'seja-o-nosso-parceiro',
    loadChildren: () =>
      import('./pages/walkthrough/walkthrough-routes').then((m) => m.walkthroughRoutes),
  },
  {
    path: HomeRoutesEnum.HOME,
    loadChildren: () =>
      import('./home/home-routes').then((m) => m.homeRoutes),
    canActivate: [AuthGuard],
  },
  {
    path: AuthRoutesEnum.AUTH,
    loadChildren: () =>
      import('./auth/auth-routes').then((m) => m.authRoutes),
  },
  {
    path: ProfileRoutesEnum.PROFILE,
    loadChildren: () =>
      import('./pages/profile/profile-routes').then((m) => m.profileRoutes),
    canActivate: [AuthGuard],
  },
  {
    path: OpeningHoursRoutesEnum.OPENING_HOURS,
    loadChildren: () =>
      import('./pages/opening-hours/opening-hours.module').then(
        (m) => m.OpeningHoursPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: CourtsRoutesEnum.COURTS,
    loadChildren: () =>
      import('./pages/court/court-routes').then((m) => m.courtRoutes),
    canActivate: [AuthGuard],
  },
  {
    path: 'fixed-time',
    loadChildren: () => fixedTimeRoutes
  },
  {
    path: ClassesRoutesEnum.CLASSES,
    loadChildren: () => classesRoutes,
  },
  {
    path: CrudSportCenterEnum.CRUD,
    loadChildren: () =>
      import('./pages/crud/crud-routes').then((m) => m.crudRoutes),
  },
  {
    path: SchedulerRoutesEnum.SCHEDULER,
    loadChildren: () =>
      import('./pages/scheduler/scheduler-routes').then(
        (m) => m.schedulerRoutes
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'qr-code',
    loadChildren: () =>
      import('./pages/qr-code/qr-code.module').then((m) => m.QrCodePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'visitor-guide',
    loadChildren: () =>
      import('./pages/visitor-guide/visitor-guide-routes').then((m) => m.visitorGuideRoutes),
    canActivate: [AuthGuard],
  },
  {
    path: EmployeeRoutesEnum.EMPLOYEE,
    loadChildren: () =>
      import('./pages/employee/employee.module').then(
        (m) => m.EmployeePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: NotificationRoutesEnum.NOTIFICATIONS,
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        (m) => m.NotificationsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: SettingsPageEnum.SETTINGS,
    loadChildren: () =>
      import('./pages/settings/settings-routing').then(
        (m) => m.settingsRoutes
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'equipments',
    loadChildren: () =>
      import('./pages/equipments/equipments.module').then(
        (m) => m.EquipmentsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'pacotes',
    loadChildren: () =>
      import('./pages/packages/packages.module').then(
        (m) => m.PackagesPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'customers',
    loadChildren: () =>
      import('./pages/customers/customers-routes').then(
        (m) => m.customersRoutes
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'financial-dashboard',
    loadChildren: () => financialDashboardRoutes
  },
  {
    path: 'chat',
    loadChildren: () => chatRoutes
  },
  {
    path: 'shop',
    loadChildren: () => import('@libs/shop').then(m => m.shopRoutes),
    canActivate: [() => commonEnvironment.featuresFlags.shop],
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('./pages/pricing/pricing-routes').then(
        (m) => m.pricingRoutes
      )
  },
  {
    path: 'privacy',
    loadComponent: () => import('./pages/privacy/privacy.page').then(m => m.PrivacyPage),
  },
  {
    path: 'timezone',
    loadChildren: () => import('./pages/timezone/timezone-routes').then(m => m.timezoneRoutes),
  },
  {
    path: 'language',
    children: updateLanguageRoutes
  },
  {
    path: 'clients',
    children: clientRoutes
  },
  {
    path: 'restaurant',
    canActivate: [() => commonEnvironment.featuresFlags.restaurant],
    loadChildren: () => import('@libs/restaurant').then(m => m.restaurantRoutes)
  },
  {
    path: 'tournaments',
    canActivate: [() => commonEnvironment.featuresFlags.tournament],
    loadChildren: () => import('@libs/tournament').then(m => m.tournamentRoutes),
  },
  {
    path: 'add-athlete',
    loadComponent: () => import('./pages/add-athlete/add-athlete.page').then(m => m.AddAthletePage),
  }, {
    path: 'step-by-step',
    loadChildren: () => import('./pages/step-by-step/step-by-step-routes').then(m => m.StepByStepRoutes),
  }, {
    path: 'transfer-booking',
    loadChildren: () => import('@libs/transfer-booking').then(m => m.TransferBookingRoutes),
  },{
    path: 'bloquear-periodo',
    loadChildren: () => import('./pages/block-period/block-periodo.routes').then(m => m.blockPeriodRoutes),
  },{
    path: 'maintenance',
    loadChildren: () => maintenanceRoutes,
  },
  {
    path: '**',
    redirectTo: HomeRoutesEnum.HOME,
  } // rota catch-all para redirecionar para a Home
];

