import { Routes } from '@angular/router';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { SettingsComponent } from './settings/settings.component';

export const maintenanceRoutes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent
  },
  {
    path: ':appId',
    component: MaintenanceComponent
  }
];
