import { inject, Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Canal } from '@libs/entity-lib';
import { onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore';

export interface MaintenanceStatus {
  active: boolean;
  message: string;
  time: string;
  expiresAt: string;
  updatedAt?: string;
}

@Injectable()
export class FirebaseMaintenanceService {
  private firestore = inject(Firestore);

  path = 'maintenance';

  constructor(
    private toastController: ToastController,
    private router: Router
  ) {}

  async createOrUpdateMaintenance(
    type: string,
    maintenanceStatus: MaintenanceStatus
  ): Promise<void> {
    const path = this.path;
    const maintenanceDocRef = doc(this.firestore, `${path}/${type}`);

    // Obter o horário atual em UTC
    const nowUTC = new Date();

    // Extrair duração em horas e minutos
    const { hours, minutes } = this.extractTimeDuration(maintenanceStatus.time);

    // Converter "HH:MM" para milissegundos
    const timeInMilliseconds = (hours * 60 * 60 + minutes * 60) * 1000;

    // Calcular o horário final em UTC
    const expiresAtUTC =
      timeInMilliseconds > 0
        ? new Date(nowUTC.getTime() + timeInMilliseconds).toISOString()
        : null;

    // Salvar o documento no Firestore
    await setDoc(maintenanceDocRef, {
      ...maintenanceStatus,
      updatedAt: serverTimestamp(),
      expiresAt: expiresAtUTC,
    });

    this.presentToastTop(
      `${type} maintenance status updated successfully.`,
      3000,
      'success'
    );
  }

  async deleteMaintenance(type: Canal): Promise<void> {
    const path = this.path;
    const maintenanceDocRef = doc(this.firestore, `${path}/${type}`);
    await setDoc(maintenanceDocRef, {
      active: false,
      updatedAt: serverTimestamp(),
    });
    this.router.navigate(['/']);
  }

  /**
   * Verifica o status de manutenção no Firestore para um tipo específico
   */
  async fetchMaintenanceStatus(appId: string): Promise<MaintenanceStatus> {
    const maintenanceDocRef = doc(this.firestore, `maintenance/${appId}`);
    const snapshot = await getDoc(maintenanceDocRef);

    if (snapshot.exists()) {
      return snapshot.data() as MaintenanceStatus;
    } else {
      return {
        active: false,
        message: 'No momento, estamos em manutenção.',
        time: '00:10',
        expiresAt: new Date().toISOString(),
      };
    }
  }

  async presentToastTop(msg: string, dur: number, col: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: dur,
      position: 'bottom',
      color: col,
    });

    await toast.present();
  }

  private extractTimeDuration(time: string): {
    hours: number;
    minutes: number;
  } {
    // Verifica se o valor é válido no formato "HH:MM"
    if (!time || !/^\d{2}:\d{2}$/.test(time)) {
      this.presentToastTop(
        'Modifique o Horário. Expected "HH:MM".',
        3000,
        'danger'
      );
      throw new Error('Invalid time format. Expected "HH:MM".');
    }

    // Extrai horas e minutos
    const [hours, minutes] = time.split(':').map(Number);

    return {
      hours: isNaN(hours) ? 0 : hours,
      minutes: isNaN(minutes) ? 0 : minutes,
    };
  }

  /**
   * Verifica alterações em tempo real no status de manutenção com base no `appId`.
   * Redireciona para `/maintenance/:appId` se a manutenção estiver ativa.
   * @param appId - O ID do aplicativo a ser monitorado
   */
  checkMaintenance(appId: Canal): void {
    const docRef = doc(this.firestore, `maintenance/${appId}`);

    // Configura um ouvinte em tempo real
    onSnapshot(
      docRef,
      (snapshot) => {
        if (snapshot.exists()) {
          const maintenanceStatus = snapshot.data() as { active: boolean };

          if (maintenanceStatus.active) {
            // Redirecionar para a página de manutenção
            this.router.navigate([`/maintenance/${appId}`]);
          }
        } else {
          console.warn(`Documento para ${appId} não encontrado.`);
        }
      },
      (error) => {
        console.error('Erro ao ouvir mudanças no status de manutenção:', error);
      }
    );
  }

  /**
   * Obtém o status de manutenção em tempo real e executa um callback ao detectar alterações.
   * @param appId O ID do aplicativo
   * @param callback Callback para executar quando houver alterações no documento
   */
  listenToMaintenanceStatus(
    appId: string,
    callback: (status: MaintenanceStatus) => void
  ): void {
    const maintenanceDocRef = doc(this.firestore, `maintenance/${appId}`);

    // Configurar o ouvinte em tempo real
    onSnapshot(maintenanceDocRef, (snapshot) => {
      if (snapshot.exists()) {
        const status = snapshot.data() as MaintenanceStatus;
        callback(status);
        if (!status.active) {
          this.router.navigate(['/']);
        }
      } else {
        callback({
          active: false,
          message: 'No momento, estamos em manutenção.',
          time: '00:10',
          expiresAt: new Date().toISOString(),
        });
      }
    });
  }
}
