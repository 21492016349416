import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Capacitor } from '@capacitor/core';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { ToastController } from '@ionic/angular';
import { CanalTokenType, NotificationRegisterRequest } from '@libs/entity-lib';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as fromNotificationActions from '../../store/notification/notification.actions';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NotificationListenersService implements OnDestroy {
  private token$$ = new BehaviorSubject<any>(null);
  token$ = this.token$$.asObservable();

  private subscriptions = new Subscription();

  constructor(
    private store: Store,
    private toastController: ToastController,
    private angularFireMessaging: AngularFireMessaging,
    private router: Router
  ) {}

  init() {
    this.checkPlatform();
  }

  private checkPlatform(): void {
    if (Capacitor.getPlatform() !== 'web') {
      this.initialisePushNotifications();
    } else {
      this.messages();
    }
  }

  private initialisePushNotifications() {
    // Capturar a notificação inicial, se houver
    PushNotifications.getDeliveredNotifications()
      .then((notifications) => {
        console.log('Notificações entregues:', JSON.stringify(notifications));
        if (notifications && notifications.notifications.length > 0) {
          const initialNotification = notifications.notifications[0];
          const redirectUrl = initialNotification.data?.redirectUrl;
          if (redirectUrl) {
            console.log(
              'Redirecionando pela notificação inicial:',
              redirectUrl
            );
            this.router.navigateByUrl(redirectUrl);
          }
        }
      })
      .catch((err) =>
        console.error('Erro ao buscar notificações entregues:', err)
      );

    PushNotifications.addListener('registration', (token: Token) => {
      const notificationRequest = {
        token: token.value,
        canalTokenType: CanalTokenType.MOBILE,
      } as NotificationRegisterRequest;
      this.presentToastTop(
        'Configuração concluída! Você começará a receber notificações em breve.',
        2500,
        'success'
      );
      this.store.dispatch(
        fromNotificationActions.registerTokenMobileSuccess({
          token: token.value,
        })
      );
      this.store.dispatch(
        fromNotificationActions.registerToken({ notificationRequest })
      );
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      this.presentToastTop('Error ao ativar notificações', 2500, 'error');
      this.store.dispatch(
        fromNotificationActions.fetchAllNotificationsFailed({ error })
      );
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('notification: ', notification);
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('notification: ', JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('[Push Notifications] Ação de notificação:', notification);
        const redirectUrl = notification.notification.data?.redirectUrl;
        if (redirectUrl) {
          this.router.navigateByUrl(redirectUrl);
        }
      }
    );
  }

  private messages() {
    this.subscriptions.add(
      this.angularFireMessaging.messages.subscribe({
        next: (messagePayload) => {
          this.token$$.next(JSON.stringify(messagePayload));
        },
        error: (error: any) => {
          this.token$$.next(JSON.stringify(error));
          console.error('Error getting message:', error);
        },
      })
    );
  }

  async presentToastTop(msg: string, dur: number, col: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: dur,
      position: 'bottom',
      color: col,
    });

    await toast.present();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
