import { Component, signal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslationLibModule } from '@libs/translation';
import { FirebaseMaintenanceService, MaintenanceStatus } from '../../services/firebase-maintenance.service';
import { FormsModule } from '@angular/forms';
import { Canal } from '@libs/entity-lib';

@Component({
    selector: 'maintenance-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    imports: [IonicModule, TranslationLibModule, FormsModule],
    providers: [FirebaseMaintenanceService]
})
export class SettingsComponent {
  // Define signals para armazenar o status de manutenção
  maintenanceTypes = signal([
    {
      key: Canal.SPORT_CENTER,
      name: 'Lessor',
      status: signal({ active: false, message: 'No momento, estamos em manutenção.', time: '00:10', expiresAt: '' }),
    },
    {
      key: Canal.ATHLETE,
      name: 'Athlete',
      status: signal({ active: false, message: 'No momento, estamos em manutenção.', time: '00:10', expiresAt: '' }),
    },
  ]);

  status = signal<MaintenanceStatus>({ active: false, message: 'No momento, estamos em manutenção.', time: '00:10', expiresAt: '' });

   // Controle de modificações pendentes
   modifiedTypes = signal<Record<string, boolean>>({});

  constructor(private maintenanceService: FirebaseMaintenanceService) {
    // Carregar os dados iniciais ao criar o componente
    this.loadMaintenanceStatus();
  }

  /**
   * Carrega o status de manutenção de cada tipo do Firestore
   */
  private async loadMaintenanceStatus(): Promise<void> {
    for (const type of this.maintenanceTypes()) {
      try {
        const status = await this.maintenanceService.fetchMaintenanceStatus(type.key);
        type.status.set(status);
      } catch (error) {
        console.error(`Erro ao carregar status de ${type.key}:`, error);
      }
    }
  }

  /**
   * Marca um tipo como modificado
   * @param key Chave do tipo modificado
   */
  markAsModified(key: string): void {
    const currentModified = this.modifiedTypes();
    this.modifiedTypes.set({ ...currentModified, [key]: true });
  }

  /**
   * Verifica se um tipo tem alterações pendentes
   * @param key Chave do tipo
   * @returns `true` se houver alterações pendentes, `false` caso contrário
   */
  isModified(key: string): boolean {
    return !!this.modifiedTypes()[key];
  }

  /**
   * Salva alterações para um tipo específico
   * @param key Chave do tipo
   * @param status Novo status de manutenção
   */
  async saveChanges(key: string, status: MaintenanceStatus): Promise<void> {
    try {
      await this.maintenanceService.createOrUpdateMaintenance(key, status);
      console.log(`Manutenção para ${key} salva com sucesso.`);
      const currentModified = this.modifiedTypes();
      this.modifiedTypes.set({ ...currentModified, [key]: false });
    } catch (error) {
      console.error(`Erro ao salvar manutenção para ${key}:`, error);
    }
  }
}
