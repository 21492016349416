import { Component, input, OnInit, signal } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { Canal } from '@libs/entity-lib';
import { TranslationLibModule } from '@libs/translation';
import { FirebaseMaintenanceService, MaintenanceStatus } from '../../services/firebase-maintenance.service';

@Component({
    selector: 'maintenance-page',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    imports: [IonicModule, TranslationLibModule],
    providers: [FirebaseMaintenanceService]
})
export class MaintenanceComponent implements OnInit {
  appId = input<Canal>();

  timeLeft = signal('00:00:00');
  message = signal('No momento, estamos em manutenção.');

  // Propriedade para armazenar o ID do intervalo
  private intervalId!: number;

  constructor(private firebaseMaintenanceService: FirebaseMaintenanceService) {
    
  }

  ngOnInit(): void {
    const appId = this.appId() ?? Canal.ATHLETE;

    this.firebaseMaintenanceService.listenToMaintenanceStatus(appId, (status) => {
      clearInterval(this.intervalId);
      this.updateMaintenanceStatus(status);
    });
  }

  /**
   * Atualiza o estado de manutenção local
   */
  private updateMaintenanceStatus(status: MaintenanceStatus): void {
    // Adicional: Reiniciar cronômetro ou lógica relacionada se necessário
    if (status.expiresAt) {
      this.startCountdown(status.expiresAt);
    }
  }

  /**
   * Inicia o cronômetro decrescente com base no timestamp do campo time
   * @param time Timestamp ISO 8601 representando o fim da manutenção (em UTC)
   */
  private startCountdown(expiresAt: string): void {
    // Obter o horário atual em UTC
    const nowUTC = new Date().getTime(); // Horário atual em milissegundos UTC
    const expiresAtUTC = new Date(expiresAt).getTime(); // Converter `expiresAt` para milissegundos UTC
  
    // Calcular o tempo restante
    let remainingMilliseconds = expiresAtUTC - nowUTC;
  
    // Se o tempo já expirou
    if (remainingMilliseconds <= 0) {
      this.timeLeft.set('00:00:00');
      const appId = this.appId() ?? Canal.ATHLETE;
      this.firebaseMaintenanceService.deleteMaintenance(appId);
      return;
    }
  
    // Atualizar o tempo restante inicialmente
    this.updateTimeLeft(remainingMilliseconds);
  
    // Configurar o intervalo para decrementar o tempo
    this.intervalId = setInterval(() => {
      remainingMilliseconds -= 1000; // Reduz 1 segundo em milissegundos
  
      if (remainingMilliseconds <= 0) {
        this.timeLeft.set('00:00:00');
        const appId = this.appId() ?? Canal.ATHLETE;
        this.firebaseMaintenanceService.deleteMaintenance(appId);
        clearInterval(this.intervalId); // Para o cronômetro ao atingir 0
      } else {
        this.updateTimeLeft(remainingMilliseconds);
      }
    }, 1000) as unknown as number;
  }
  
  /**
   * Atualiza o tempo restante no formato HH:MM:SS
   * @param remainingMilliseconds Tempo restante em milissegundos
   */
  private updateTimeLeft(remainingMilliseconds: number): void {
    const hours = Math.floor(remainingMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((remainingMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((remainingMilliseconds % (1000 * 60)) / 1000);
  
    this.timeLeft.set(
      `${this.padNumber(hours)}:${this.padNumber(minutes)}:${this.padNumber(seconds)}`
    );
  }
  
  /**
   * Adiciona um zero à esquerda em números menores que 10
   */
  private padNumber(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
  
}
