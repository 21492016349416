<ion-header class="ion-no-border">
  <ion-toolbar class="header-toolbar">
    <ion-title class="header-title" translate>Voltamos em Breve!</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="maintenance-content">
  <div class="img-container">
    <img class="maintenance-img" src="assets/icon/maintenance/repairing-service.svg" alt="Manutenção">
  </div>
  <div class="description">
    <h2>Estamos em Manutenção</h2>
    <p>
      {{ message() }}
    </p>
    @if(timeLeft(); as remainingTime) {
      <p>
        Voltaremos em: {{ remainingTime }}
      </p>
    }
  </div>
</ion-content>
